<template>
  <div>
    <div v-if="handlesLocal.length === 0" class="p-a-m">
      There are no {{ type }} social handles, click below to add one
    </div>
    <newsroom-section-social-input
      v-for="handle in handlesLocal"
      v-else
      :key="handle.id"
      v-model="handle.handle"
      :type="type"
      :is-loading="isLoading.includes(handle.id)"
      @keyup.enter="saveSingleHandle(handle)"
      @keyup.esc="$emit('cancel')"
    >
      <v-button
        :loading="isDeleting.includes(handle.id)"
        :disabled="handlesLocal.length === 1 && !handle.handle"
        class="is-plain is-black is-narrow"
        @click="removeHandle(handle.id)"
      >
        <v-icon type="times" />
      </v-button>
    </newsroom-section-social-input>
    <v-button
      class="is-plain is-black"
      data-testid="NewsroomSocialHandleAddTrigger"
      @click="addNewHandle"
    >
      {{ $t('components.newsroom_edit_social_links_group.add_new') }}
    </v-button>
  </div>
</template>

<script>
import NewsroomSectionSocialInput from '@hypefactors/shared/js/components/newsroom/NewsroomSectionSocialInput'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'

/**
 * Generates a dropdown on click to edit a social network
 * @module NewsroomEditSocialLinksGroup
 */
export default {
  components: {
    NewsroomSectionSocialInput
  },
  props: {
    type: {
      type: String,
      required: true
    },
    handles: {
      type: Array,
      required: true
    },
    newsroomId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      // cached local value
      handlesLocal: [],
      isLoading: [],
      isDeleting: []
    }
  },
  computed: {
    updatedHandles () {
      return this.handlesLocal.filter(h => {
        if (h.__notSaved__) return true
        const propSocialHandle = this.handles.find(propHandle => propHandle.id === h.id)
        return propSocialHandle && h.handle !== propSocialHandle.handle
      })
    }
  },

  watch: {
    handles (value) {
      this.handlesLocal = value
    }
  },

  methods: {
    async saveSingleHandle (socialHandle) {
      // return if no handle typed
      if (!socialHandle.handle) return
      // should update or create
      this.isLoading.push(socialHandle.id)
      try {
        if (socialHandle.__notSaved__) {
          // create
          const handleResponse = await NewsroomService.createSocialHandle(this.newsroomId, socialHandle)
          const index = this.handlesLocal.findIndex(h => h.id === socialHandle.id)
          this.handlesLocal.splice(index, 1, handleResponse)
          this.$emit('created', handleResponse)
          this.$notify.success(this.$t('components.newsroom_edit_social_links_group.created_successfully', { type: socialHandle.type }))
        } else {
          const handleResponse = await NewsroomService.updateSocianHandle(this.newsroomId, socialHandle.id, socialHandle)
          this.$emit('updated', handleResponse)
          this.$notify.success(this.$t('components.newsroom_edit_social_links_group.updated_successfully', { type: socialHandle.type }))
        }
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        const index = this.isLoading.indexOf(socialHandle.id)
        this.isLoading.splice(index, 1)
      }
    },

    async saveAll () {
      if (!this.updatedHandles.length) return
      await Promise.all(this.updatedHandles.map(this.saveSingleHandle))
    },
    addNewHandle () {
      this.handlesLocal.push(this.socialHandleFactory())
    },
    async removeHandle (id) {
      // find by id
      const index = this.handlesLocal.findIndex(h => h.id === id)
      const handle = this.handlesLocal[index]
      // check if its temporary or not
      if (!handle.__notSaved__) {
        // delete from server if saved
        this.isDeleting.push(handle.id)
        await NewsroomService.deleteSocialHandle(this.newsroomId, id)
        this.$emit('removed', id)
        this.$notify.success(this.$t('components.newsroom_edit_social_links_group.deleted_successfully', { type: handle.type }))
        const delIndex = this.isDeleting.indexOf(handle.id)
        this.isDeleting.splice(delIndex, 1)
      }
      // delete locally
      if (index === -1) return
      this.handlesLocal.splice(index, 1)
    },
    socialHandleFactory () {
      return { id: Date.now(), handle: '', type: this.type, __notSaved__: true }
    }
  }
}
</script>
