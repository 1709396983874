<template>
  <form-field :class="type" class="has-addons NewsroomSectionSocialInput">
    <template #control>
      <div class="control">
        <div
          :class="`is-${type}`"
          class="button is-aligned-start pointer-none"
        >
          <div class="icon">
            <v-icon :type="type" />
          </div>
          <span class="is-hidden-mobile">{{ strippedUrl }}</span>
        </div>
      </div>

      <div class="control is-expanded" :class="{ 'is-loading': isLoading }">
        <input
          ref="field"
          :placeholder="placeholder"
          :value="value"
          type="text"
          class="input"
          v-on="listeners"
        >
      </div>
      <div class="control">
        <slot />
      </div>
    </template>
  </form-field>
</template>

<script>
import { extractHandleFromSocialUrl } from '@hypefactors/shared/js/utils'

const URL_MAP = {
  facebook: 'https://www.facebook.com/',
  twitter: 'https://www.twitter.com/',
  instagram: 'https://www.instagram.com/',
  linkedin: 'https://www.linkedin.com/company/',
  youtube: 'https://www.youtube.com/channel/'
}

export default {
  name: 'NewsroomSectionSocialInput',
  inheritAttrs: false,

  props: {
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default () {
        return this.$t(`components.newsroom_section_social.${this.type}_input_placeholder`)
      }
    },
    value: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      localCache: ''
    }
  },

  computed: {
    url () {
      return URL_MAP[this.type]
    },
    strippedUrl () {
      return this.url.replace('https://www.', '')
    },
    listeners () {
      return {
        ...this.$listeners,
        change: (e) => this.handleInput('change', e),
        input: (e) => this.handleInput('input', e)
      }
    }
  },

  watch: {
    value (newVal, oldVal) {
      if (newVal && newVal !== this.localCache) {
        this.extractHandleFromUrl(newVal)
      }
    }
  },

  methods: {
    handleInput (eventName, e) {
      const value = this.$safeGet(e, 'target.value', '')
      this.$emit(eventName, this.extractHandleFromUrl(value))
    },
    extractHandleFromUrl (value) {
      const ref = this.$refs.field

      const {
        isSocialUrl,
        isHttp,
        handleExists,
        handlePart
      } = extractHandleFromSocialUrl(this.type + '.com', value)

      if (isHttp && isSocialUrl && handleExists) {
        ref.value = handlePart
        this.localCache = handlePart

        this.$notify.success({
          title: this.$t('general.info'),
          message: this.$t('success.extract_handle_from_url', { domain: this.url }),
          duration: 10000
        })
        return handlePart
      } else if (isHttp) {
        this.$notify({
          title: this.$t('general.warning'),
          message: this.$t('errors.extract_handle_from_url'),
          type: 'warning'
        })
      } else {
        this.localCache = handlePart
        return handlePart
      }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.NewsroomSectionSocialInput {
  @include tablet {
    &.field > .control:first-of-type {
      min-width: 180px;

      .button {
        width: 100%;
      }
    }
  }
}
</style>
