<template>
  <div class="NewsroomSectionVideo company-videos p-b-l">
    <transition name="fade" mode="out-in">
      <template v-if="!isEditing">
        <div class="NewsroomSectionVideo__empty-block">
          <a
            class="has-text-primary"
            @click="startEditing"
          >
            {{ $t('components.newsroom_section_video.add_video') }}
          </a>
        </div>
      </template>

      <template v-if="isEditing">
        <div class="NewsroomSectionVideo__field-block columns is-centered is-flex is-aligned-middle">
          <div class="column is-9">
            <div class="field">
              <form-field class="company-video">
                <input
                  :placeholder="$t('components.newsroom_section_video.input_placeholder')"
                  :value="videoId"
                  class="input"
                  @input="handleVideoInput"
                  @keyup.esc="stopEditing"
                >
              </form-field>
            </div>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'
import getVideoId from 'get-video-id'

import { hasHttp } from '@hypefactors/shared/js/utils'

export default {
  name: 'NewsroomSectionVideo',

  mixins: [debounceMixin(['handleVideoInput'])],

  props: {
    value: {
      type: Object,
      default: () => ({ type: '', id: '' })
    }
  },

  data () {
    return {
      isEditing: false,

      videoType: '',
      videoId: '',

      videoServiceUrls: {
        youtube: 'https://youtube.com/',
        vimeo: 'https://vimeo.com'
      }
    }
  },

  watch: {
    value (newValue, oldValue) {
      if (newValue) {
        this.videoType = newValue.type
        this.videoId = newValue.id
      } else {
        this.clear()
      }
    }
  },

  methods: {
    clear () {
      this.videoType = ''
      this.videoId = ''
    },

    save () {
      this.$emit('input', {
        id: this.videoId,
        type: this.videoType
      })
    },

    startEditing () {
      this.isEditing = true
    },

    stopEditing () {
      this.clear()
      this.isEditing = false
    },

    handleVideoInput (e) {
      const value = e.target.value

      if (!value) {
        this.clear()
        return
      }

      if (!hasHttp(value)) {
        this.videoId = value

        this.$alert(this.$t('warnings.manually_typing_video_link'), {
          type: 'warning'
        }).catch(() => { })
      }

      const videoData = getVideoId(value)
      if (!['youtube', 'vimeo'].includes(videoData.service)) {
        this.videoId = value
        this.$notify.error(this.$t('errors.video_provider_not_supported'))

        return
      }

      this.videoType = videoData.service
      this.videoId = videoData.id
      this.save()
      this.$notify.success(this.$t('success.picked_id_video_service', { service: videoData.service }))
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.NewsroomSectionVideo {
  &__empty-block {
    height: 20em;
    color: $primary;
    border: 1px solid $primary;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__field-block {
    height: 20em;
    border: 1px solid $primary;
    background-color: white;
    margin: 0;
  }
}
</style>
