<template>
  <div class="content-wrapper">
    <transition name="fade" mode="out-in">
      <div v-if="form.videos[0]" class="company-page-block p-b-l">
        <video-from-service :video="form.videos[0]" />
        <div class="has-text-centered">
          <v-button class="is-primary" @click="removeVideo">
            <span><i class="hf hf-trash" /></span>
            {{ $t('components.story_newsroom_edit.newsroom_edit_about.remove_video') }}
          </v-button>
        </div>
      </div>
      <template v-else>
        <newsroom-section-video
          :value="form.videos[0]"
          @input="updateVideo"
        />
      </template>
    </transition>

    <newsroom-edit-social-links-data-provider
      #default="{ groupedHandles, handleRemove, handleCreate, handleUpdate, handles }"
      :newsroom-id="newsroom.id"
    >
      <div>
        <div class="company-page-block">
          <div class="columns is-mobile is-multiline is-aligned-justify">
            <div class="column is-4 p-b-none">
              <div
                class="is-aligned-start has-background-facebook is-cursor-pointer p-a-s"
                @click="currentHandle = 'facebook'"
              >
                <i class="hf hf-fw hf-facebook" />
                <span class="is-hidden-mobile">Facebook</span>
              </div>
            </div>

            <div class="column is-4 p-b-none">
              <div
                class="is-aligned-start has-background-twitter is-cursor-pointer p-a-s"
                @click="currentHandle = 'twitter'"
              >
                <i class="hf hf-fw hf-twitter" />
                <span class="is-hidden-mobile">Twitter</span>
              </div>
            </div>

            <div class="column is-4 p-b-none">
              <div
                class="is-aligned-start has-background-instagram is-cursor-pointer p-a-s"
                @click="currentHandle = 'instagram'"
              >
                <i class="hf hf-fw hf-instagram" />
                <span class="is-hidden-mobile">Instagram</span>
              </div>
            </div>

            <div class="column is-12 p-v-none">
              <div class="has-background-white p-a-m">
                <newsroom-edit-social-links-group
                  ref="handlesGroup"
                  v-click-away="handleClickAway"
                  :handles="groupedHandles[currentHandle]"
                  :newsroom-id="newsroom.id"
                  :type="currentHandle"
                  @removed="handleRemove"
                  @created="handleCreate"
                  @updated="handleUpdate"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="company-page-block m-t-l">
          <newsroom-social-links-embeds :handles="handles" :newsroom-id="newsroom.id" />
        </div>
      </div>
    </newsroom-edit-social-links-data-provider>
  </div>
</template>

<script>
import NewsroomChild from '@/pages/newsroom/NewsroomChild'

import VideoFromService from '@/components/newsroom/VideoFromService'
import NewsroomSectionVideo from '@/components/newsroomEdit/NewsroomSectionVideo'
import NewsroomSocialLinksEmbeds from '@/components/newsroom/NewsroomSocialLinksEmbeds'
import NewsroomEditSocialLinksGroup from '@/components/newsroomEdit/NewsroomEditSocialLinksGroup'
import NewsroomEditSocialLinksDataProvider from '@/components/newsroomEdit/NewsroomEditSocialLinksDataProvider'

export default {
  name: 'NewsroomEditAbout',

  components: {
    NewsroomSocialLinksEmbeds,
    NewsroomEditSocialLinksGroup,
    NewsroomEditSocialLinksDataProvider,
    VideoFromService,
    NewsroomSectionVideo
  },

  extends: NewsroomChild,

  props: {
    form: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      pageName: 'About',
      currentHandle: 'facebook'
    }
  },

  methods: {
    updateVideo (event) {
      this.$emit('update', { videos: [event] })
    },

    removeVideo () {
      this.$confirm(this.$t('components.story_newsroom_edit.newsroom_edit_about.confirm_video_removal'))
        .then(() => {
          this.$emit('update', { videos: [] })
        })
        .catch((e) => {})
    },

    handleClickAway () {
      this.$refs.handlesGroup.saveAll()
    }
  }
}
</script>
